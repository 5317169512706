import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'
import createPersistedState from "vuex-persistedstate";
import AccountService from "@/services/AccountService";

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    userProfile: {},
    telegram_chat_id: '-1001359112082',
    telegram_rekt_traders_chat_id: '-1001804791694',
    telegram_koz_trader_chat_id: '-1001854976676'
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setPosts(state, val) {
      state.posts = val
    }
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async signup({ dispatch }, form) {
      const registerRequest = await AccountService.register(form.code)
      if (registerRequest.data.success) {
        // sign user up
        const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
        // create user object in userCollections
        await AccountService.createAccount(form.code)
        // fetch user profile and set in state
        dispatch('fetchUserProfile', user)
      } else {
       throw new Error('Invalid code')
      }
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()
      const idTokenResult = await fb.auth.currentUser.getIdTokenResult()
      // set user profile in state
      commit('setUserProfile', {
        ...userProfile.data(),
        uid: user.uid,
        isAdmin: !!idTokenResult.claims.admin
      })

      // change route to dashboard
      if (['/login', '/register'].includes(router.currentRoute.path)) {
        await router.push('/account')
      }
    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})

      // redirect to login view
      await router.push('/login')
    }
  }
})

export default store
