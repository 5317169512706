<template>
  <div id="app">
    <div class="card-toggle">
      <buttonDarkMode />
    </div>
    <router-view />
  </div>
</template>
<script>
import buttonDarkMode from "@/components/buttonDarkMode.vue";

export default {
  name: "AccountMyAccountView",
  components: {
    buttonDarkMode,
  },
};
</script>
<style lang="scss">
.app {
  background-color: var(--app-background-color);
}
.columns {
  background-color: var(--app-background-color);
}
.hero-body {
  padding: 0;
}
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 76px);
}
</style>
