import axios from "axios";
import { auth } from "@/firebase"

const API_URL = process.env.VUE_APP_API_URL;

export default () => {
    // const firebaseIdToken = await auth.currentUser.getIdToken()
    // console.log(firebaseIdToken)
    const axiosInstance = axios.create({
        baseURL: API_URL,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            // 'token': firebaseIdToken
        }
    });
    return axiosInstance
};
