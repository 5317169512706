<template>
  <div id="darkModeButton">
    <input
      @change="toggleTheme"
      id="checkbox"
      type="checkbox"
      class="switch-checkbox"
    />
    <label for="checkbox" class="switch-label">
      <span class="switch-icon">🌙</span>
      <span class="switch-icon">☀️</span>
      <div
        class="switch-toggle"
        :class="{
          'switch-toggle-checked': userTheme === 'dark-theme',
          'switch-toggle-unchecked': userTheme === 'light-theme',
        }"
      ></div>
    </label>
  </div>
</template>

<script>
export default {
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },

  data() {
    return {
      userTheme: "light-theme",
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch-checkbox {
  display: none;
}

.switch-label {
  align-items: center;
  background: var(--background-fully-transparent);
  border: calc(var(--element-size) * 0.001) solid var(--accent-color);
  border-radius: var(--element-size);
  cursor: pointer;
  display: flex;
  font-size: calc(var(--element-size) * 0.26);
  height: calc(var(--element-size) * 0.35);
  position: relative;
  transition: background 0.5s ease;
  justify-content: space-between;
  width: calc(var(--element-size) * 0.99);
  z-index: 1;
}

.switch-toggle {
  position: absolute;
  background-color: var(--toggle-button);
  border: calc(var(--element-size) * 0.001) solid var(--accent-color);
  border-radius: 50%;
  top: calc(var(--element-size) * 0.007);
  left: calc(var(--element-size) * 0.01);
  height: calc(var(--element-size) * 0.33);
  width: calc(var(--element-size) * 0.35);
  transform: translateX(0);
  transition: transform 0.3s ease, background-color 0.5s ease;
}
.switch-icon {
  padding: 1%;
}
.switch-toggle-checked {
  transform: translateX(calc(var(--element-size) * 0.6));
}
#darkModeButton {
  float: right;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
}
</style>
